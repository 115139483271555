<template>
  <div class="room" :style="{ '--backimg': backcssVars() }">
    <div class="room-right-box" v-if="showlll">
      <div class="room-right">
        <div>确定邀请机器人</div>
        <div>(注:本网站拼箱机器人获奖概率与玩家完全相同)</div>
        <div>
          <span @click="showlll = false">取消</span>
          <span @click="goParticipate(3, location)">确认</span>
        </div>
      </div>
    </div>
    <div class="room-box">
      <div class="room-left">
        <div class="room-left-hint">
          <!-- pc端对战页面显示所有房间的地方 -->
          <div class="roomleft-btn">
            <div @click="goLucky">
              <img src="../assets/img/ranking/zuo.png" />返回
            </div>

            <div class="title1">
              <div class="t" v-if="playmode == 1">
                <img src="../assets/img/ranking/jingdian-bg.png" alt="" />
              </div>
              <div class="t" v-if="playmode == 2">
                <img src="../assets/img/ranking/feiqiu-bg.png" alt="" />
              </div>
            </div>
            <div
              class="close"
              v-if="battleData.status == 1 && battleData.home_owner == homeid"
              @click="cancelRoom"
            >
              取消房间
            </div>
          </div>
        </div>
        <div class="room-left-box">
          <div class="roombox-warp">
            <div class="room-info-top">
              <!-- 上方状态 -->
              <div class="first">
                <div
                  class="point"
                  :class="[`point_${battleData.status}`]"
                ></div>
                <span :class="[`text_${battleData.status}`]">
                  {{ ["等待中", "进行中", "已结束"][battleData.status - 1] }}
                </span>
              </div>
              <div class="seconed">
                <span v-if="battleData.status == 1 || battleData.status == 3"
                  >共{{ battleData.box_num }}回合</span
                >
                <span v-if="battleData.status == 2"
                  >回合数：{{
                    step > battleData.box_num ? battleData.box_num : step
                  }}/{{ battleData.box_num }}</span
                >
              </div>
              <div class="threed">
                <span
                  >总消耗：<img src="../assets/img/money.png" alt="" /><span
                    class="price"
                    >{{ Number(battleData.total_price).toFixed(2) }}</span
                  ></span
                >
              </div>
            </div>
            <!-- 用户选择的武器 -->
            <ul>
              <li
                v-for="(item, index) in battleData.boxInfo"
                :key="index"
                :id="step == index + 1 ? 'room-li' : ''"
              >
                <div
                  class="roombox-num"
                  @click="getBot(item.name, item.box_id)"
                >
                  <div class="roombox-img">
                    <img
                      :style="item.image ? '' : 'display: none'"
                      :src="item.image"
                    />
                    <img
                      :style="item.active_image ? '' : 'display: none'"
                      :src="item.active_image"
                    />
                  </div>
                  <div class=""></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="room-left-people">
          <div class="roompe-warp">
            <div class="roompe-line" v-if="roomLineState"></div>
            <!-- 等待中 -->
            <ul class="ul1 ul2" v-if="battleData.status == 1">
              <li
                class="li1"
                v-for="(item, index) in battleData.player_info"
                :key="index"
                :style="{ width: item.width }"
                :class="
                  item.winState ? '' : battleData.status == 3 ? 'fail' : ''
                "
              >
                <div
                  class="room-warp room-warps"
                  :class="[
                    item.winState ? 'room-win-status' : 'room-fail-status',
                  ]"
                >
                  <div class="room2">
                    <!-- <template v-if="battleData.status == 1"> -->
                      <div class="room2-swap">
                        <!-- 等待对战 -->
                        <div class="room2-tou">
                          <img
                            :src="item.image"
                            :style="{ opacity: item.status == 1 ? '1' : '0' }"
                          />
                        </div>
                        <div class="room2-name">
                          {{ item.name }}
                        </div>
                      </div>
                    <!-- </template> -->

                      <!-- v-if="battleData.status != 3" -->
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                    >
                      <img
                        v-if="item.totalPrice"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.totalPrice }}</span>
                    </p>
                    <!-- <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                      v-if="battleData.status == 3"
                    >
                      <img
                        v-if="item.open_value"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.open_value }}</span>
                    </p> -->
                  </div>
                  <!-- 等待对战 -->
                  <div class="room1" v-if="battleData.status == 1">
                    <div class="room1-img" v-if="item.status == 1">
                      <img src="../assets/img/ranking/yes.png" />
                      <div>准备就绪</div>
                    </div>
                    <template
                      v-if="item.status == 0 && homeid == battleData.home_owner"
                    >
                      <div
                        class="room1-img"
                        @click="goParticipate(1, index + 1)"
                      >
                        <img src="../assets/img/ranking/join.png" />
                        <div>邀请官方人机</div>
                      </div>
                    </template>
                    <template v-else-if="item.status == 0">
                      <div
                        class="room1-img"
                        @click="goParticipate(2, index + 1)"
                      >
                        <img src="../assets/img/ranking/join.png" />
                        <div>加入游戏</div>
                      </div>
                    </template>
                  </div>
                </div>
              </li>
              <li class="li1" v-if="!showNav"></li>
            </ul>
            <!-- 对战or结束-->
            <ul class="ul1" v-else>
              <li
                class="li1"
                v-for="(item, index) in battleData.player_info"
                :key="index"
                :style="{ width: item.width }"
                :class="
                  item.is_winner_mark == 1
                    ? ''
                    : battleData.status == 3
                    ? 'fail'
                    : ''
                "
              >
                <div class="game-top" v-if="battleData.status == 3">
                  <div class="game-img">
                    <img
                      class="win-img"
                      :src="imgs"
                      alt=""
                      v-if="item.is_winner_mark == 1"
                      srcset=""
                    />
                    <img
                      v-else
                      class="fail-img"
                      src="../assets/img/ranking/win1.png"
                      alt=""
                      srcset=""
                    />
                    <!-- <p
                      class="prize-price"
                      :class="item.is_winner_mark == 1 ? '' : 'failprize-price'"
                    >
                      <img src="../assets/img/money.png" alt="" />
                      <span>{{ item.price_end }}</span>
                    </p> -->
                  </div>
                </div>
                <!-- 电脑端 -->
                <div
                  class="game-bottom"
                  v-if="battleData.status == 2 && showNav"
                >
                  <div class="pool" v-if="openProducts.length > 0">
                    <ul
                      :style="{
                        transform: `translate3d(0px, ${dynamicDeg}px, 0px)`,
                      }"
                    >
                      <li
                        v-for="(itemBox, indexBox) in openProducts[index]"
                        :key="indexBox"
                        :class="[
                          getColorPz(itemBox.type_id),
                          {
                            'pool-li2': peopleList.length == 2,
                            'pool-li3': peopleList.length == 3,
                            'pool-li4': peopleList.length == 4,
                          },
                        ]"
                      >
                        <div class="box-img1 333">
                          <img :data="indexBox" :src="itemBox.img" />
                        </div>
                       
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="room-warp room-warps"
                  :class="[
                    item.is_winner_mark == 1
                      ? 'room-win-status'
                      : 'room-fail-status',
                  ]"
                >
                  <div class="room2">
                    <div class="room2-swap">
                      <div class="room2-tou">
                        <img :src="item.image" />
                      </div>
                     
                      <div class="room2-name">
                        {{ item.name }}
                      </div>
                     
                    </div>
                    <!-- <p
                      class="prize-price"
                      :class="item.is_winner_mark == 1 ? '' : 'failprize-price'"
                      v-if="battleData.status == 1"
                    >
                      <img
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.totalPrice }}</span>
                    </p> -->
                    <!-- 对战过程中金额计算 -->
                    <p
                      class="prize-price"
                      :class="item.is_winner_mark == 1 ? '' : 'failprize-price'"
                      v-if="battleData.status == 2"
                    >
                      <img
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{
                        battleData.player_info[index].actual_total_amount
                      }}</span>
                    </p>
                    <!-- 对战结束金额计算 -->
                    <p
                      class="prize-price"
                      :class="item.is_winner_mark == 1 ? '' : 'failprize-price'"
                      v-if="battleData.status == 3"
                    >
                      <img
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.price_end }}</span>
                    </p>
                  </div>
                  <!-- 手机端对战过程 -->
                  <div
                    class="game-bottom"
                    v-if="battleData.status == 2 && !showNav"
                  >
                    <div class="pool" v-if="openProducts.length > 0">
                      <ul
                        :style="{
                          transform: `translate3d(0px, ${dynamicDeg}px, 0px)`,
                        }"
                      >
                        <li
                          v-for="(itemBox, indexBox) in openProducts[index]"
                          :key="indexBox"
                          :class="[
                            getColorPz(itemBox.type_id),
                            {
                              'pool-li2': peopleList.length == 2,
                              'pool-li3': peopleList.length == 3,
                              'pool-li4': peopleList.length == 4,
                            },
                          ]"
                        >
                          <div class="box-img1 444">
                            <img :src="itemBox.img" />
                          </div>
                         
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- 手机端结算之后 -->
                  <template v-if="battleData.status == 3">
                    <div class="win-list">
                      <ul
                        class="win-ul"
                        :class="{ 'win-ul4': peopleList.length == 4 }"
                      >
                        <li
                          class="win-li"
                          v-for="(item1, index1) in item.skin_list"
                          :key="index1"
                          :class="getColorPz(item1.color)"
                        >
                          <div class="box-img1 555">
                            <img :src="item1.img" />
                          </div>
                          <div class="box-img-name" :title="item.name">
                            <div class="list-pirce">
                              <div class="pirce-left">
                                <img src="../assets/img/money.png" />
                                <span>{{ item1.price }}</span>
                              </div>
                             
                            </div>
                            {{ item1.name }}
                           
                          </div>
                        </li>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                      </ul>
                    </div>
                  </template>
                  <!-- 手机端对战过程列表 -->
                  <template
                    v-if="battleData.status == 2 && winner_owner.length > 0"
                  >
                    <div class="win-list">
                      <ul
                        class="win-ul"
                        :class="{ 'win-ul4': peopleList.length == 4 }"
                      >
                        <template
                          v-for="(item1, index1) in winner_owner[index]"
                        >
                          <li
                            class="win-li"
                            :key="index1"
                            :class="getColorPz(item1.color)"
                            v-if="!item1.hide"
                          >
                            <div class="box-img1">
                              <img :src="item1.img" />
                            </div>
                            <div class="box-img-name" :title="item.name">
                              <div class="list-pirce">
                                <div class="pirce-left">
                                  <img src="../assets/img/money.png" />
                                  <span>{{ item1.price }}</span>
                                </div>
                              </div>
                              {{ item1.name }}
                            </div>
                          </li>
                        </template>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                        <li
                          style="width: 140px; height: 0; visibility: hidden"
                        ></li>
                      </ul>
                    </div>
                  </template>
                </div>
              </li>
              <div class="room-line" v-if="lineState"></div>
            </ul>
          </div>
        </div>
        <div class="room-left-bot"></div>
      </div>
      <div class="biaoti-box" v-if="drawerBot">
        <div class="top">
          <span>包含以下奖励</span>
          <span @click="drawerBot = false"
            ><img src="../assets/img/ranking/cha.png" alt=""
          /></span>
        </div>
        <div class="gailv" v-if="skins_types && skins_types.length > 0">
          <!-- 掉落概率 -->
          <span v-for="(skin, index) in skins_types" :key="index">
            <span
              class="probability-icon"
              :style="{ backgroundColor: skin.color }"
            ></span>
            {{ parseFloat(skin.skins_type_probability).toFixed(2) }}%
          </span>
        </div>
        <div class="bottom">
          <ul>
            <li
              class="li"
              :class="getColorPz(item.color)"
              v-for="(item, index) in drawerList"
              :key="index"
            >
              <div class="box-img-name" :title="item.name">
                {{ getFrist(item.name) }} | {{ getLast(item.name) }}
              </div>
              <div class="box-img1 666">
                <img :src="item.img" />
              </div>
              <div class="libottom">
                <div class="pirce-right">
                  {{ extractValuesInParentheses(item.name) }}
                </div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/img/money.png" />
                    <span>{{ item.price }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <audio controls ref="notify1" class="audio" loop="loop">
        <source src="../assets/audio/battle.mp3" />
      </audio>
    </div>
    <myhomebot></myhomebot>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";

import Utils from "./../assets/js/util.js";
import { battle } from "@/api/socket.js";
export default {
  components: {
    myhomebot,
  },
  props: ["showNav"],
  data() {
    return {
      homeId: "",
      battleId: "",
      skins_types: [],
      imgs: require("../assets/img/ranking/win.png"),
      add: false,
      disabled: false,
      loading: false,
      roomLineState: false,
      battleData: [],
      id: "",
      winList: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
      ],
      homeid: null,
      loser: [
        {
          name: "",
          img: "",
          price: "0.01",
        },
      ],
      boxList: [],
      boxListIndex: 0,
      fightboxList: [],
      fightImg: [],
      fightImgObj: [],
      peopleList: [],
      peopleObj: {
        name: "凉凉奖励",
        width: "",
        state: true,
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        loading: false,
      },
      step: 0,
      img: "",
      lineState: false,
      openWin: false,
      drawerList: [],
      drawerName: null,
      drawerBot: false,
      mode: "",
      showlll: false,
      openBox: [
        {
          state: false,
        },
      ],
      totalPrice: 0,
      playmode: "",

      adds: false,
      cartState: false,
      ount: false,
      animationClass: "",
      animationDuration: "",
      location: 1,
      battleResults: [],
      openProducts: [],
      dynamicDeg: -50,
      showIndex: -1,
      winner_owner: [],
    };
  },
  // watch: {
  //   add(val) {
  //     if (val == false) {
  //       this.getRoomList1();
  //     }
  //   },
  // },
  mounted() {
    this.id = this.$route.query.id;
    this.getRoomList();
    this.getUserInfo();
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    //关闭
    //clearInterval(this.timer);
    this.websock.close();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      let param = {};
      this.$axios
        .post("/api/player/playerdata", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.code == "1") {
            localStorage.setItem("userInfo", JSON.stringify(data.data.player));
            if (localStorage.getItem("userInfo")) {
              this.homeid = JSON.parse(localStorage.getItem("userInfo")).id;
            }
          }
        });
    },
    // 取消房间
    cancelRoom() {
      this.$axios({
        url: "/api/battle/closebattle",
        method: "post",
        data: { battle_id: this.battleData.id },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
            customClass: "messageStyle",
          });
          this.getRoomList();
          this.$router.go(-1);
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "messageStyle",
          });
        }
      });
    },
    // 背景图片
    backcssVars() {
      return `url("${this.img}")`;
    },
    //音乐 播放
    playAlarm1() {
      this.$refs.notify1.currentTime = 0;
      this.$refs.notify1.play();
    },
    //音乐 结束
    playAlarm2() {
      this.$refs.notify1.pause(); // 暂停播放
      this.$refs.notify1.currentTime = 0; // 将播放位置重置到开头
    },
    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() {},

    websocketonerror() {
      this.initWebSocket();
    },
    websocketonmessage(d) {
      let da;
      try {
        da = JSON.parse(d.data);
      } catch (e) {
        // console.error("Received data is not a valid JSON:", d.data);
        return;
      }

      if (da.info) {
        this.sockBattleList(da);
      }
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      //console.log("close:", e);
    },

    async sockBattleList(data) {
      console.log("推送数据->:", data.info);
      if (data.info) {
        if (data.info.id == this.id) {
          if (data.info.status != 3) {
            await this.getRoomList();
            this.sortPosition();
          }
          if (data.info.status == 3) {
            // console.log("状态3")
            // this.add = false;
            this.getBattleResult();
          }
        }
      }
    },
    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
      });
    },
    getColorPz(typeId) {
      // switch (typeId) {
      //   case 1:
      //     return "yellow";
      //   case 4:
      //     return "purple";
      //   case 5:
      //     return "red";
      //   case 6:
      //     return "blue";
      // }
      return "blue";
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },

    removeValueFromArray(array, value) {
      return array.filter((item) => item !== value);
    },
    ccc() {
      var cc = "";
      cc += "[";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += "]";
      console.log(cc);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },

    generateRandomArray(length) {
      const result = [];
      for (let i = 0; i < length; i++) {
        result.push(i);
      }
      this.shuffleArray(result);
      return result;
    },

    //跳转
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
    //获取房间信息
    getRoomList(over, tt) {
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/api/battle/battledetail", this.$qs.stringify(param))
        .then((res) => {
          const data = res.data;
          if (data.code == "1") {
            this.playmode = data.data.playmode;
            this.open_box_num = data.data.open_box_num;
            this.battleData = data.data;
            this.mode = data.data.mode;
            this.peopleList = data.data.player_info;
            this.fightboxList = data.data.boxInfo;
            this.sortPosition();
            if (this.battleData.status == 3) {
              this.getBattleResult();
            } else if (this.battleData.status == 2) {
              // 判断是刚开始对战还是进房间观看
              if (this.open_box_num == 0) {
                this.fightResultInfo();
                return;
              } else {
                this.step = this.open_box_num;
                this.fightResultInfo(true);
                return;
              }
            }
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    // 获取对战结果
    getBattleResult() {
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/api/battle/resultinfoc", this.$qs.stringify(param))
        .then((res) => {
          const data = res.data;
          if (data.code == "1") {
            this.battleData.player_info = data.data.player_info;
          }
        });
    },

    getBot(name, id) {
      this.drawerBot = true;
      this.drawerName = name;
      let param = {
        box_id: id,
      };
      // /index/Box/boxInfo
      this.$axios
        .post("/api/box/boxinfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.code == 1) {
            this.drawerList = data.data.box_skins;
            this.skins_types = data.data.skins_types;
          }
        });
      console.log(this.drawerList);
    },

    //房间观看对战 请求对战结果
    fightResultInfo(bool) {
      let _this = this;
      if (this.ount) {
        return;
      }
      this.ount = true;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/api/battle/resultinfo", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.code == 1) {
            this.battleResults = data.data.result;
            this.winner_owner = Array.from(
              { length: this.battleData.mode },
              () => []
            );
            const battleResults = this.battleResults;
            battleResults.forEach((item) => {
              item.slice(0, this.battleData.mode).forEach((value, i) => {
                const valueWithHide = {
                  ...value, // 展开原有的value对象
                  hide: true, // 添加hide字段，默认为true
                };
                this.winner_owner[i] = [valueWithHide, ...this.winner_owner[i]];
              });
            });

            if (bool) {
              this.winner_owner.forEach((item, index) => {
                let amount = 0;
                // 计算的数据
                const data = item.slice(-this.step);
                data.forEach((itm) => {
                  itm.hide = false;
                  amount = (parseFloat(itm.price) + parseFloat(amount)).toFixed(
                    2
                  );
                });
                this.battleData.player_info[index].actual_total_amount = amount;
              });
            }
            this.getOpenProducts(); //获取奖品
          }
        });
    },
    getOpenProducts() {
      this.step += 1;
      this.openProducts = [];
      if (this.step > this.battleData.box_num) {
        setTimeout(() => {
          // 对战结束
          this.over();
          this.openWin = false;
          this.roomLineState = false;
          this.showIndex = -1;
        }, 2000);
        return;
      }
      this.dynamicDeg = -50;
      // this.lineState = false
      this.roomLineState = false;
      this.$forceUpdate();
      // 获取箱子里面饰品信息
      setTimeout(() => {
        let params = {
          box_id: this.battleData.boxInfo[this.step - 1].box_id,
        };
        this.$axios
          .post("/api/box/boxinfo", this.$qs.stringify(params))
          .then((res) => {
            var data = res.data;
            if (data.code == 1) {
              const newArray = [];
              // 补齐50个元素
              for (let i = 0; i < 44; i++) {
                // 修改为50以符合需求
                const index = i % data.data.box_skins.length;
                newArray.push(data.data.box_skins[index]);
              }

              // 动态初始化openProducts
              this.openProducts = Array.from(
                { length: this.battleData.mode },
                () => [...newArray]
              );

              // 添加特定的battleResult项目
              const addBattleResultItem = (teamIndex, battleResultIndex) => {
                const itemToAdd = newArray.find(
                  (item) =>
                    item.id ===
                    this.battleResults[this.step - 1][battleResultIndex].id
                );
                if (itemToAdd) {
                  // 打乱数据
                  this.openProducts[teamIndex] = this.shuffleArray(
                    this.openProducts[teamIndex]
                  );
                  this.openProducts[teamIndex].push(itemToAdd);
                  // 增加随机数据
                  const additionalItemsCount = 5;
                  if (
                    this.openProducts[teamIndex].length > additionalItemsCount
                  ) {
                    const additionalItems = this.openProducts[teamIndex].slice(
                      -additionalItemsCount
                    );
                    this.openProducts[teamIndex].push(...additionalItems);
                  }
                }
              };

              for (let i = 0; i < this.battleData.mode; i++) {
                addBattleResultItem(i, i); // 根据当前模式和队伍索引来添加对应的battleResult项
              }
              this.$forceUpdate();
              this.showDynamic();
            }
          });
      }, 500);
    },
    showDynamic() {
      const animationStep = 100 / this.battleData.boxInfo.length;
      setTimeout(() => {
        this.dynamicDeg = -4405;
        this.roomLineState = true;
        this.playAlarm1();
        console.log("开启声音");
        setTimeout(() => {
          // 开始动画
          this.winner_owner.forEach((items, index) => {
            let amount = 0;
            for (let i = 1; i <= this.step; i++) {
              if (items.length >= i) {
                // 确保不会访问越界
                amount += parseFloat(items[items.length - i].price);
                items[items.length - i].hide = false;
              }
            }
            this.battleData.player_info[index].actual_total_amount = parseFloat(
              amount.toFixed(2)
            );
          });
          this.$forceUpdate();
          this.playAlarm2();
        }, 6500);
        setTimeout(() => {
          setTimeout(() => {
            this.getOpenProducts();
          }, 500);
        }, 8000);
      }, 500);
    },

    //对局结束
    over() {
      var _this = this;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/api/battle/setbattlestatus", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == "1") {
            _this.getRoomList("over");
          }
        });
    },
    //点击参与活动
    goParticipate(type, location) {
      this.location = location;
      if (type == 1) {
        this.showlll = true;
        return;
      }
      if (type == 2) {
        const is_join = this.battleData.player_info.filter(
          (item) => item.id == this.homeid
        );
        if (is_join.length > 0) {
          this.$message({
            message: "已加入",
            type: "warning",
          });
          return;
        }
      }
      this.showlll = false;
      // this.loading = true;
      this.disabled = true;
      let _this = this;
      var param = {
        battle_id: this.id,
        location: this.location,
      };
      _this.$axios
        .post("/api/battle/addbattle", _this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(123)
          // console.log(data);
          if (data.code == "1") {
            this.loading = false;
            //扣除余额
            this.$parent.getPlayerInfo();
            //人数已满 开始对战
            // if (data.data.battle_status == "start") {
            //   let box = data.data.result;
            //   this.mode = box.length;
            //   this.add = false;
            //   this.getRoomList();
            //   setTimeout(function () {
            //     _this.playAlarm1(); // 开启音乐
            //   }, 1000);
            // }
          } else {
            this.disabled = false;
            this.loading = false;
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
      // this.loading = false;
    },
    sortPosition() {
      this.battleData.player_info.sort((a, b) => {
        return a.location - b.location;
      });
    },
    // 洗牌打乱数据
    shuffleArray(array, count) {
      // 创建数组的一个副本以避免原地修改原始数组
      let shuffled = array.slice();

      // 执行洗牌算法
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // 使用解构赋值交换元素
      }

      // 根据count参数返回相应数量的元素，如果没有提供count或者超出范围，则返回整个数组
      return count === undefined || count > array.length
        ? shuffled
        : shuffled.slice(0, count);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css/luckyroom.less";

.biaoti-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.641) !important;
  border-radius: 5px !important;
  width: 1120px;
  height: 600px;
  padding: 16px;
  border: 1px solid #3a4525;
  box-shadow: 0 17px 57px 0 rgba(0, 0, 0, 0.77);
  color: white;
  font-family: "微软雅黑";
  z-index: 99999;

  .top {
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    img {
      width: 30px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    overflow-y: auto;
    margin-top: 22px;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 540px;
      font-size: 12px;

      .blue {
        background-image: url("../assets/img/openBox/d.png");
        background-size: 100% 100%;
      }
      .purple {
        background-image: url("../assets/img/openBox/b.png");
        background-size: 100% 100%;
      }

      .red {
        background-image: url("../assets/img/openBox/c.png");
        background-size: 100% 100%;
      }

      .yellow {
        background-image: url("../assets/img/openBox/e.png");
        background-size: 100% 100%;
      }

      // .blue:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg1.png");
      // }

      // .red:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg6.png");
      // }

      // .yellow:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg7.png");
      // }

      li {
        margin-top: 12px;
        margin-left: 4px;
        width: 180px;
        height: 180px;
        background: #191926;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-top: 12px;
        .libottom {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .pirce-left {
          display: flex;
          align-items: center;

          span {
            margin-left: 4px;
            color: rgb(117, 220, 158);
            font-weight: 600;
          }
        }

        .box-img1 img {
          width: 100px;
          height: 100px;
        }

        img {
          width: 20px;
        }
      }
    }
  }
}

.room-win-status {
  background-color: transparent !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}

.room-fail-status {
  background-color: transparent !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}

.room::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  //   background: var(--backimg) no-repeat center center;
  /*  z-index:1; */
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
}

.room-right-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.room-right {
  width: 350px;
  height: 200px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  // background-color: #24252f;
  background: rgba(50, 19, 59, 0.7);
  border: 1px solid rgba(50, 19, 59, 1);
  border-radius: 10px;
  color: white;
  z-index: 999;
  text-align: center;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  div:first-child {
    color: yellow;
  }

  div {
    color: rgba(100, 103, 107, 1);
  }

  div:last-child {
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      border: 2px solid rgba(113, 43, 134, 1);
      border-radius: 20px;
      display: block;
      padding: 8px 32px;
    }
  }
}

.room {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  z-index: 2;
  background-color: #160e39;

  .audio {
    display: none;
  }

  .room-box {
    // background: url('../assets/img/main-bg.png') no-repeat;
    height: 100%;
    background-position: 50% 0%;
    background-size: 95%;
    padding: 0 6px !important;
  }

  .room-left {
    // padding: 16px 316px 0 16px;
    width: 100vw;
    max-width: 1440px;
    margin: auto;

    .title {
      height: 57px;
      font-family: YouSheBiaoTiHei;
      position: relative;
      max-width: 700px;
      width: 100vw;
      margin: 0 auto;
      padding: 50px 0;
    }

    .title::after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../assets/img/title-bg.png") center no-repeat;
      background-size: auto;
      background-size: 100%;
      z-index: 10;
      top: 0;
    }

    .title .title-text {
      position: absolute;
      top: 52px;
      font-size: 36px;
      z-index: 11;
      left: 50%;
      margin-left: -100px;
      width: 200px;
      text-align: center;
      color: #fff;
    }

    .title .en-title {
      position: absolute;
      top: 20px;
      font-size: 50px;
      color: #392782;
      opacity: 0.3;
      z-index: 9;
      left: 50%;
      margin-left: -100px;
      width: 200px;
      text-align: center;
    }

    .room-left-hint {
      margin-top: 20px;
      // display: flex;
      // justify-content: flex-start;
      position: relative;

      .roomleft-num {
        display: flex;
        align-items: center;
        color: #848492;
        font-size: 15px !important;

        span {
          margin-right: 5px;

          &:last-child:hover {
            color: #e9b10e;
          }
        }
      }

      .roomleft-btn {
        width: 100%;
        margin-right: -5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .close {
          border: 1px solid rebeccapurple;
          border-radius: 3px;
          @media (max-width: 550px) {
            padding: 5px !important;
          }
        }

        div {
          padding: 6px 15px;
          color: #fff !important;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #c9c9f1 !important;

          img {
            width: 32px;
          }
        }

        /deep/.el-switch__label {
          color: #fff !important;
        }
      }
    }

    .room-left-box {
      // margin-top: 10px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      //   background-color: #2b2c37;
      justify-content: center;
      width: 100%;

      .roombox-warp {
        width: 100%;

        .room-info-top {
          border-bottom: 1px solid #22252f;
          margin-top: 10px;
          background: #3c256d;
          padding: 0 20px;
          height: 64px;
          border-radius: 10px 10px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .first {
            display: flex;
            align-items: center;

            span {
              margin-left: 6px;
            }

            .point_1 {
              // background-color: #e9b10e;
              width: 18px;
              height: 18px;
              background: url("../assets/img/ranking/dd.png");
            }

            .point_2 {
              width: 21px;
              height: 16px;
              background: url("../assets/img/ranking/jxz.png");
            }

            .point_3 {
              width: 24px;
              height: 24px;
              background: url("../assets/img/ranking/yjs.png");
            }

            .text_1 {
              color: #fb9a84;
            }

            .text_2 {
              color: #67c23a;
            }

            .text_3 {
              color: #999;
            }
          }

          .seconed {
            margin-left: 60px;
            color: #74b0ff;
            font-family: "微软雅黑";

            span:first-child {
            }
          }

          .threed {
            span {
              display: flex;
              align-items: center;
              color: #74b0ff;
            }

            .price {
              font-weight: 600;
              color: #ffc83c;
              font-family: "微软雅黑";
              margin-left: 4px;
            }

            img {
              width: 20px;
            }
          }
        }

        ul {
          display: flex;
          align-items: center;
          // justify-content: center;
          overflow: auto;
          overflow-y: hidden;
          padding: 20px 0 20px 0;
          background: #2a1350;
          border-radius: 0 0 10px 10px;

          li {
            width: 100px;
            height: 100px;
            margin-left: 20px;

            .roombox-num {
              display: flex;
              align-items: center;
              // margin: 10px 15px 15px 15px;
              position: relative;

              .roombox-img {
                width: 100px;
                height: 100px;
                position: relative;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                  cursor: pointer;
                  opacity: 0.3;
                }

                img:last-child {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  z-index: 99;
                  margin: auto;
                }
              }

              .roombox-num1 {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                margin-right: 30px;
                color: #e9b10e;
                position: relative;
                font-size: 14px;
                margin: 0 auto;
              }

              // .roombox-num1::before {
              //   top: 0;
              //   left: 0;
              //   width: 100%;
              //   border: 1px solid #e9b10e;
              //   height: 100%;
              //   content: "";
              //   position: absolute;
              //   transform: rotate(45deg);
              //   box-sizing: border-box;
              // }
              .roombox-line {
                width: 60px;
                height: 1px;
                background-color: #e9b10e;
              }
            }
          }

          li:last-child .roombox-line {
            display: none;
          }

          #room-li {
            position: relative;

            &::before {
              // content: "";
              // position: absolute;
              // width: 100%;
              // height: 100%;
              // top: 0;
              // left: 0;
              // //   background-image: url("../assets/new/bg_border.png");
              // background-size: 100% 100%;
              // background-image: url("../assets/img/lucky/choose.png");
              //   transform: rotate(180deg);
            }

            .roombox-num1 {
              width: 30px;
              height: 30px;
              line-height: 30px;
            }

            .roombox-img {
              width: 100px;
              height: 100px;
              position: relative;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                cursor: pointer;
                animation: flip 2.5s infinite alternate;
                -webkit-animation: flip 2.5s infinite alternate;
                opacity: 1;
              }

              @keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }
              @-webkit-keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }

              img:last-child {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 99;
                margin: auto;
              }
            }

            .roombox-num1::before {
              box-shadow: 0px 0px 7px #e9b10e;
            }
          }
        }
      }
    }

    .room-left-people {
      .roompe-warp {
        position: relative;

        .ul1 {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
          gap: 10px;

          .fail {
            opacity: 0.5;
          }

          .li1 {
            width: 100%;
            // max-width: 300px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            .game-bottom {
              height: 110px;
              line-height: 110px;
              border: 1px solid #6f3bdf;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              padding: 24px 0;
              background: #23124c !important;
              /* background-image: linear-gradient(
							  rgba(43, 44, 55, 1),
							  rgba(35, 71, 59, 1)
							);*/
              // border-top-left-radius: 5px;
              // border-top-right-radius: 5px;
              text-align: center;
              position: relative;
              overflow: hidden;

              .pool {
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                z-index: 2;

                ul {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  transition-timing-function: ease-in-out;
                  transition-duration: 6s;
                  // animation: run 7s;
                  // animation: run 7s;
                  // -webkit-animation: run 7s;
                  // animation-timing-function: cubic-bezier(
                  //   0.0001,
                  //   0.9,
                  //   0.6,
                  //   1
                  // ) !important; //动画慢 快 慢
                  // animation-timing-function: ease-out; //动画 速度一样
                  // animation-iteration-count: 1 !important; //播放几次动画
                  // -webkit-animation-iteration-count: 1 !important; //播放几次动画
                  // animation-delay: 0s; //动画运行前等待时间
                  // -webkit-animation-delay: 0s; //动画运行前等待时间
                  // animation-fill-mode: forwards !important; //动画结束 是否保持
                  // -webkit-animation-fill-mode: forwards !important; //动画结束 是否保持
                  padding-top: 32px;

                  .blue {
                    background-image: url("../assets/img/ranking/arms-bg1.png");
                  }

                  .red {
                    background-image: url("../assets/img/ranking/arms-bg6.png");
                  }

                  .yellow {
                    background-image: url("../assets/img/ranking/arms-bg7.png");
                  }

                  .blue:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg1.png");
                  }

                  .red:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg6.png");
                  }

                  .yellow:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg7.png");
                  }

                  li {
                    width: 150px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-size: 100% 100%;
                    margin: auto;
                    position: relative;

                    .box-img1 {
                      position: relative;

                      img {
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }

                    .box-img-name {
                      color: white;
                      font-size: 12px;
                      position: absolute;
                      height: 30px;
                      line-height: 30px;
                      left: 4px;
                      bottom: 1%;
                      display: inline-block;
                      width: 100%;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      text-align: left;
                      position: absolute;
                    }
                  }
                }

               
              

               
              }

              .room1-img {
                //   background-image: linear-gradient(
                //     rgba(43, 44, 55, 1),
                //     rgba(35, 71, 59, 1)
                //   );
                img {
                  margin-top: 40px;
                  width: 30px;
                  height: 30px;
                }

                .room1-text2 {
                  margin-top: 0px;
                  font-size: 20px;
                  color: #848492;
                }

                .room1-text1 {
                  margin-top: 0px;
                  font-size: 20px;
                  color: #e9b10e;
                }
              }

              button {
                margin-top: -50px;
                padding: 8px 10px;
                background-color: #e9b10e;
                border-radius: 8px;
                color: #1a1c24;
                font-size: 8px;
                font-weight: 400;
              }

              button:hover {
                cursor: pointer;
              }
            }

            .game-top {
              border: 1px solid #6f3bdf;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              padding: 24px 0;

              .win-img {
                animation: flip 2.5s infinite alternate;
                -webkit-animation: flip 2.5s infinite alternate;
              }

              @keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }
              @-webkit-keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }

              img {
                width: 250px;
                height: 112px;
              }

              .prize-price {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "微软雅黑";
                font-weight: 600;
                color: #ffc83c;

                img {
                  width: 20px !important;
                  height: 20px !important;
                }

                span {
                  margin-left: 5px;
                  font-size: 14px;
                }

                font-size: 12px;
              }
            }

            .room-warp {
              width: 100%;

              .room1 {
                padding: 48px 0;
                border: 1px solid #6f3bdf;
                // background: #23124C !important;
                /* background-image: linear-gradient(
                  rgba(43, 44, 55, 1),
                  rgba(35, 71, 59, 1)
                );*/
                // border-top-left-radius: 5px;
                // border-top-right-radius: 5px;
                text-align: center;
                position: relative;
                overflow: hidden;

                .pool {
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background-size: 100% 100%;
                  z-index: 2;

                  .blue {
                    background-image: url("../assets/img/ranking/arms-bg1.png");
                  }

                  .red {
                    background-image: url("../assets/img/ranking/arms-bg6.png");
                  }

                  .yellow {
                    background-image: url("../assets/img/ranking/arms-bg7.png");
                  }

                  .blue:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg1.png");
                  }

                  .red:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg6.png");
                  }

                  .yellow:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg7.png");
                  }

                  ul {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    // animation: run 5s;
                    // animation-timing-function: ease-out; //动画 速度一样
                    // animation-iteration-count: 1; //播放几次动画
                    // animation-delay: 0s; //动画运行前等待时间
                    // animation-fill-mode: forwards; //动画结束 是否保持
                    // -webkit-animation: run 5s;
                    // -webkit-animation-timing-function: ease-out; //动画 速度一样
                    // -webkit-animation-iteration-count: 1; //播放几次动画
                    // -webkit-animation-delay: 0s; //动画运行前等待时间
                    // -webkit-animation-fill-mode: forwards; //动画结束 是否保持

                    li {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;

                      .pool-img {
                        height: 100px;

                        img {
                          object-fit: contain;
                          height: 100px;
                          width: auto;
                          margin: 0 auto;
                        }
                      }
                    }
                  }

                 
                 
                }

                .room1-img {
                  //   background-image: linear-gradient(
                  //     rgba(43, 44, 55, 1),
                  //     rgba(35, 71, 59, 1)
                  //   );
                  img {
                    width: 120px;
                    height: 120px;
                  }

                  .room1-text2 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #848492;
                  }

                  .room1-text1 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #e9b10e;
                  }

                  div {
                    color: #20c288;
                    margin-top: 20px;
                  }
                }

                .room1-img:last-child {
                  div {
                    color: #9a4bff !important;
                  }
                }

                button {
                  margin-top: -50px;
                  padding: 8px 10px;
                  background-color: #e9b10e;
                  border-radius: 8px;
                  color: #1a1c24;
                  font-size: 8px;
                  font-weight: 400;
                }

                button:hover {
                  cursor: pointer;
                }
              }

              .room2 {
                display: flex;
                align-items: center;
                padding: 0 24px;
                height: 90px;
                border: 2px solid rgba(180, 85, 255, 0.2) !important;
                display: flex;
                align-items: center;
                padding-left: 20px;
                background: linear-gradient(
                  180deg,
                  rgba(130, 71, 255, 0) 0%,
                  rgba(191, 10, 255, 0.2) 100%
                );
                padding-right: 20px;
                justify-content: space-between;

                .room2-swap {
                  display: flex;
                  align-items: center;
                }

                .prize-price {
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: "微软雅黑";
                  font-weight: 600;

                  img {
                    width: 20px !important;
                    height: 20px !important;
                  }

                  span {
                    padding-top: 0;
                    margin-left: 5px;
                    color: #ffc83c !important;
                    font-size: 14px;
                  }
                }

                .room2-tou {
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }

                .room2-name {
                  font-size: 14px;
                  margin-left: 12px;
                  font-weight: 500;
                  color: #fff;
                  font-size: 16px;
                  font-family: "微软雅黑";
                }

                span {
                  font-size: 14px;
                  color: #848492;
                  display: inline-block;
                  padding-top: 50px;
                }
              }
            }
          }

          .room-line:before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            z-index: 8;
            top: 60px;
            left: 0;
            background-color: #e9b10e;
            box-shadow: 0px 0px 7px #e9b10e;
          }
        }
      }

      .roompe-line {
        position: absolute;
        left: 0;
        top: 80px;
        width: 100%;
        height: 2px;
        z-index: 9;
        background-color: #e9b10e;
      }

      // .roompe-warp::before {
      // 	content: "";
      // 	display: block;
      // 	width: 0;
      // 	height: 0;
      // 	border-top: 10px solid transparent;
      // 	border-left: 20px solid #e9b10e;
      // 	border-bottom: 10px solid transparent;
      // 	position: absolute;
      // 	left: 0;
      // 	top: 51px;
      // 	z-index: 10;
      // }

      // .roompe-warp::after {
      // 	content: "";
      // 	display: block;
      // 	width: 0;
      // 	height: 0;
      // 	border-top: 10px solid transparent;
      // 	border-right: 20px solid #e9b10e;
      // 	border-bottom: 10px solid transparent;
      // 	position: absolute;
      // 	right: 0;
      // 	top: 51px;
      // 	z-index: 10;
      // }
    }

    .room-left-bot {
      margin-top: 30px;
      color: #848492;
      font-size: 12px;
      text-align: center;
      margin-bottom: 20px;

      & > p:last-child {
        color: #e9b10e;
      }

      p:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  //开奖列表
  .win-list {
    // margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background: #23124c;

    .win-title {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 24px;
        width: auto;
      }

      span {
        margin-left: 5px;
        font-size: 24px;
        color: #e9b10e;
      }
    }

    .blue {
      background-image: url("../assets/img/ranking/arms-bg1.png");
    }

    .red {
      background-image: url("../assets/img/ranking/arms-bg6.png");
    }

    .yellow {
      background-image: url("../assets/img/ranking/arms-bg7.png");
    }

    .blue:hover {
      background-image: url("../assets/img/ranking/arms-on-bg1.png");
    }

    .red:hover {
      background-image: url("../assets/img/ranking/arms-on-bg6.png");
    }

    .yellow:hover {
      background-image: url("../assets/img/ranking/arms-on-bg7.png");
    }

    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 12px 0 12px 12px;
    }

    li {
      width: 140px;
      background-size: 100% 100%;
      height: 94px;
      margin-right: 10px;
      margin-top: 12px;
      position: relative;

      .box-img1 {
        background-size: 100% 100%;
        position: relative;
        height: 94px;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 65px;
        }
      }

      .box-img-name {
        color: white;
        font-size: 12px;
        position: absolute;
        left: 4px;
        bottom: 1%;
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
      }

      .probability {
        font-size: 14px;
        position: absolute;
        right: 4px;
        top: 5px;
        font-weight: 700;
        font-family: "微软雅黑";
        color: white;
      }

      .list-pirce {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .ico {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: #c3c3e2;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px 4px;
      }

      .pirce-left {
        display: flex;
        align-items: center;

        img {
          width: auto;
          height: 20px;
        }

        span {
          color: #e9b10e;
          font-size: 14px;
          font-weight: 600;
          font-family: "微软雅黑";
          margin-left: 4px;
        }
      }

      .pirce-right {
        color: white;
        font-size: 12px;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .win-li {
        width: 140px;
        //min-width: 200px;

        .win-warp {
          margin: 5px 0;
          background-color: #2b2c37;

          .img {
            height: 150px;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            background-position: top center;
            background-image: url("../assets/img/box-skins-blue.jpg");
            // background-size: 100% 95%;
            padding: 0 5px;
            display: grid;
            place-items: center;

            img {
              width: 70%;
              height: auto;
              max-width: 200px;
            }
          }

          h6 {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            width: calc(100% - 10px);

            img {
              height: 16px;
              width: auto;
            }

            span {
              margin-left: 5px;
              font-size: 16px;
              color: #e9b10e;
            }
          }
        }
      }
    }
  }
}

//底部弹框
.box-bot {
  .box-img {
    .box-img-title {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 16px;
        color: white;
      }

      span:last-child {
        cursor: pointer;
      }

      .span1 {
        color: #e9b10e;
      }
    }

    .box-img-title1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: rgb(255, 255, 255);
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      span:last-child {
        cursor: pointer;
      }
    }

    .box-img-list {
      padding: 0 16px;

      ul {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        height: 190px;

        .blue {
          background-image: url("../assets/img/ranking/arms-bg1.png");
        }

        .red {
          background-image: url("../assets/img/ranking/arms-bg6.png");
        }

        .yellow {
          background-image: url("../assets/img/ranking/arms-bg7.png");
        }

        .blue:hover {
          background-image: url("../assets/img/ranking/arms-on-bg1.png");
        }

        .red:hover {
          background-image: url("../assets/img/ranking/arms-on-bg6.png");
        }

        .yellow:hover {
          background-image: url("../assets/img/ranking/arms-on-bg7.png");
        }

        li {
          width: 200px;
          height: 134px;
          background-size: 100% 100%;
          margin-right: 16px;
          margin-top: 12px;
          position: relative;

          .box-img1 {
            background-size: 100% 100%;
            position: absolute;
            top: 20%;
            height: 50%;
            left: 50%;
            margin-left: -23%;

            img {
              width: 85px;
            }
          }

          .box-img-name {
            color: white;
            font-size: 12px;
            position: absolute;
            left: 4px;
            bottom: 1%;
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
          }

          .probability {
            font-size: 14px;
            position: absolute;
            right: 4px;
            top: 5px;
            font-weight: 700;
            font-family: "微软雅黑";
            color: white;
          }

          .list-pirce {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #c3c3e2;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 4px;
          }

          .pirce-left {
            display: flex;
            align-items: center;

            img {
              width: auto;
              height: 20px;
            }

            span {
              color: #e9b10e;
              font-size: 16px;
              font-weight: 600;
              font-family: "微软雅黑";
              margin-left: 4px;
            }
          }

          .pirce-right {
            color: white;
            font-size: 12px;
            margin-right: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

::v-deep {
  .el-drawer__body {
    padding: 10px 0px 10px 15px;
    background: #1f1f23;
    // overflow-y: auto;
  }

  .el-drawer__header {
    background: #1f1f23;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}

.homebot {
  margin-top: 110px;
}
@media screen and (max-width: 767px) {
  .room-left {
    overflow: hidden !important;
  }
  .room-left-hint {
    padding-top: 10px;
    // margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
  .title1 {
    // margin-left: 15% !important;
    transform: translate(-48%, -43%) !important;
  }
}
.title1 {
  // margin-left: 35.5%;
  // margin-right: 400px !important;
  position: absolute;
  width: 160px;
  left: 50%;
  top: 50%;
  transform: translate(-58%, -50%);
  .t {
    width: 160px !important;
    img {
      width: 160px !important;
      height: auto;
    }
  }
}

.gailv {
  color: #fff;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 550px) {
    justify-content: flex-start !important;
  }
  span {
    display: flex;
    align-items: center;
  }
  .probability-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px 0 12px;
  }
}
</style>